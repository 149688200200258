.app-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.header-right {
  display: flex;
  align-items: center;
}

.weChat {
  cursor: pointer;
  margin-right: 24px;
}

.weChatText {
  text-align: center;
  padding: 12px 12px 6px 12px;
  border-bottom: solid 1px #0000003b;
  color: #000;
}

.gitHub {
  cursor: pointer;
}

.header_icon {
  font-size: 16px;
  color: #676f7b;
  cursor: pointer;
}

.header_icon:hover {
  color: #38404d;
}
